<template>
  <v-dialog v-model="dialog" scrollable max-width="500px" :fullscreen="$vuetify.display.mobile">
    <app-card>
      <v-card-title class="pa-4">
        <Icon name="hugeicons:magic-wand-01" class="mr-2" size="18" /> Gerar com I.A.
      </v-card-title>
      <v-divider />
      <v-card-text class="pa-4">
        <v-row>
          <v-col class="d-flex justify-end">
            <AppCard color="grey-lighten-3">
              <v-card-text class="pa-2 text-right">
                <span class="text-medium-emphasis">Gerando uma descrição para o produto</span>
                <br>
                <span>{{ form.product_name }}</span>

              </v-card-text>
            </AppCard>
          </v-col>

        </v-row>
        <v-row>
          <v-col>
            <template v-if="description">
              <div class="d-flex ga-2">
                <div>
                  <Icon name="hugeicons:magic-wand-01" class="mr-2" size="18" />
                </div>
                <div>
                  <div>
                    {{ description }}
                  </div>
                  <div class="mt-2">
                    <AppBtn class="mr-1 mt-1" size="small" variant="tonal" @click="dialog = false">
                      <Icon name="hugeicons:thumbs-down" class="mr-2" size="18" />
                      Não gostei
                    </AppBtn>
                    <AppBtn class="mr-1 mt-1" size="small" color="primary" variant="tonal" @click="handleAccept">
                      <Icon name="hugeicons:thumbs-up" class="mr-2" size="18" />
                      Aceitar
                    </AppBtn>
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <v-skeleton-loader :loading="true" type="paragraph" />
              <v-skeleton-loader :loading="true" type="paragraph" />
            </template>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider />
      <v-card-actions class="justify-end pa-4">
        <AppBackBtn @click="dialog = false" />
        <!-- <AppConfirmBtn @click="handleStore()" /> -->
      </v-card-actions>
    </app-card>
  </v-dialog>
</template>

<script setup>

const $loading = useLoading();

const $http = useNuxtApp().$http;

const emit = defineEmits(["store"]);

const dialog = ref(false);

const form = ref({
  product_name : null
})

const description = ref(null)

const open = async (productName) => {

  form.value.product_name = productName
  
  description.value = null
  
  dialog.value = true;

  createDescription()
};

const createDescription = async () => {

  $http.$post('product/ai-description', form.value)
    .then((response) => {
      description.value = response.description
    })
    .catch((error) => {
      console.log(error)
    });
};

const handleAccept = () => {
  emit("store", description.value);
  dialog.value = false;
};

defineExpose({
  open,
});
</script>

<style lang="scss" scoped></style>