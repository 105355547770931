<template>
  <v-dialog v-model="dialog" scrollable max-width="600px">
    <app-card :color="$vuetify.theme.current.colors.background">
      <v-card-title class="pa-6"> Fotos dos produtos </v-card-title>
      <v-divider />
      <v-card-text class="pa-6">
        <v-row>
          <template v-for="image in form.media">
            <v-col md="4" cols="6">
              <app-card class="d-flex align-center justify-center mb-4" height="120px" elevation="1">
                <ProductImage :media="image" />
              </app-card>
              <appBtn size="small" @click="deleteImage(image)">Remover</appBtn>
            </v-col>
          </template>
          <template v-if="canInsertImages">
            <v-col md="4" cols="6">
              <app-card class="d-flex align-center justify-center" height="120px" @click="handleAddImage()">
                <div>
                  <v-icon icon="mdi:mdi-plus" class="text-disabled" size="50px"></v-icon>
                </div>
              </app-card>
            </v-col>
          </template>
        </v-row>

      </v-card-text>
      <v-divider />
      <v-card-actions class="justify-end pa-6">
        <appBtn class="mr-2" color="primary" variant="outlined" @click="dialog = false">
          Voltar
        </appBtn>
      </v-card-actions>
    </app-card>
  </v-dialog>
  <v-file-input
    ref="fileInput"
    class="d-none"
    label="File input"
    @change="addImage"
    accept="image/*"
    multiple
    :clearable="true"
  />
</template>

<script setup>


const productForm = useProductFormStore();

const { form, isInsert } = storeToRefs(productForm);

const dialog = ref(false);

const fileInput = ref(null);

const canInsertImages = computed(() => {

  return form.value.media.length < 6;

});

const open = async () => {

  dialog.value = true;

};

const addImage = async (e) => {
  for (let i = 0; i < e.target.files.length; i++) {
    const file = e.target.files[i];

    const reader = new FileReader();

    reader.onload = (e) => {
      let image = {
        base64: e.target.result,
        name: file.name,
        size: file.size,
        type: file.type,
        is_new: true,
      };

      form.value.media.push(image);
    };

    reader.readAsDataURL(file);
  }
};

const handleAddImage = async () => {

  fileInput.value.click();

};

const deleteImage = async (image) => {

  productForm.deleteImage(image);
}

defineExpose({
  open,
});
</script>

<style lang="scss" scoped></style>