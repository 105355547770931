<template>
  <div>
    <v-dialog
      v-model="dialog"
      max-width="600px"
      :fullscreen="$vuetify.display.mobile"
    >
      <v-card>
        <v-card-title class="pa-4"> Selecionar unidade de venda </v-card-title>

        <v-divider></v-divider>

        <v-card-text class="pa-4">
          <v-list class="py-0">
            <template v-for="unity of unities">
              <v-list-item class="px-2" @click="handleSelect(unity)">
                {{ unity.name }}
                <span class="text-medium-emphasis"> ({{ unity.symbol }}) </span>
                <template v-slot:append>
                  <v-icon color="disabled" icon="mdi:mdi-chevron-right" />
                </template>
              </v-list-item>
              <v-divider></v-divider>
            </template>
          </v-list>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="pa-4">
          <v-spacer />
          <v-btn
            class="text-capitalize"
            variant="plain"
            text
            @click="dialog = false"
          >
            <v-icon class="mr-2" icon="mdi:mdi-arrow-left" />
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <app-text-field
      :modelValue="selectText"
      @click="open()"
      :label="label"
      :density="density"
      readonly
      clearable
      @click:clear="handleClear()"
    >
      <template v-slot:append-inner>
        <v-icon icon="mdi:mdi-chevron-right" @click="open()" />
      </template>
    </app-text-field>
  </div>
</template>

<script setup>
const props = defineProps({
  modelValue: {
    type: Object,
    default: null,
  },
  label: {
    type: String,
    default: "Unidade de venda",
  },

  density: {
    type: String,
    default: "default",
  },
});

const emit = defineEmits(["update:modelValue"]);

const dialog = ref(false);

const unityStore = useUnityStore();

const { unities } = storeToRefs(unityStore);

const search = ref(null);

const loading = ref(false);

const select = computed({
  get() {
    if (props.modelValue && props.modelValue.id) {
      return props.modelValue;
    } else {
      return null;
    }
  },

  set(value) {
    if (value) {
      emit("update:modelValue", {
        id: value.id,
        name: value.name,
        symbol: value.symbol,
      });
      return;
    }
    emit("update:modelValue", null);
  },
});

const selectText = computed(() => {
  if (select.value) {
    return select.value.name + " (" + select.value.symbol + ")";
  } else {
    return null;
  }
});

const getCustomers = async (value) => {
  loading.value = true;

  await unityStore.getUnities();

  loading.value = false;
};

const open = () => {
  getCustomers();

  dialog.value = true;
};

const handleSelect = (unity) => {
  select.value = unity;

  dialog.value = false;
};

const handleClear = () => {
  select.value = null;

  dialog.value = false;
};
</script>