<template>
  <v-card rounded="lg" variant="flat">
    <v-card-text class="pa-2">
      <div class="w-100 rounded-lg d-flex align-center justify-center" style="height: 120px; width: 120px;">
        <template v-if="product.media && product.media.length > 0">
          <div class="w-100 rounded-lg d-flex align-center justify-center"
            style=" background-color: #fff; height: 120px; width: 120px;">
            <v-img class="rounded-lg" :src="product.media[0].original_url" />
          </div>
        </template>
        <template v-else>
          <div>
            {{ shortName }}
          </div>
        </template>
      </div>
      <div class="mt-2">
        <span class="text-medium-emphasis mr-1" style="font-size: 12px">
          <template v-if="product.is_featured">
            <v-icon icon="mdi:mdi-star" size="small" color="#fcd800" />
          </template>
          {{ product.name }}
        </span>
        <StockAlert :product="product" />
      </div>
      <div class="d-flex align-center">
        <div>
          <b> R$ {{ $format.decimal(product.product_variants[0]?.price) }} </b>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script setup>
const props = defineProps({
  product: {
    type: Object,
    required: true,
  },
});

const shortName = computed(() => {
  if (!props.product.name) {
    return;
  }

  if (props.product.name.length <= 10) {
    return props.product.name;
  }

  return props.product.name.substring(0, 10);
});
</script>

<style lang="scss" scoped></style>