import { defineStore } from "pinia";

export const useStockTransactionFormStore = defineStore("stockTransactionFormStore", {

  state: () => ({

    http: useNuxtApp().$http,

    form: {
      product_id: null,
      product_name: null,
      product_variant_id: null,
      product_variant_name: null,
      type: null,
      quantity: 0,
      description: null,
      stock : 0,
    },

  }),

  actions: {

    async store() {
      
      await this.http.store("product/stock-transaction", this.form);

    },
  },
});
