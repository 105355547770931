<template>
  <v-dialog v-model="dialog" scrollable max-width="500px" :fullscreen="$vuetify.display.mobile">
    <app-card>
      <v-card-title class="pa-6"> Adicionar / Remover Estoque </v-card-title>
      <v-divider />
      <v-card-text class="pa-6">
        <v-row v-if="showVariantSelect">
          <v-col>
            Selecionar variação:
            <v-chip-group v-model="form.product_variant_id" mandatory>
              <template v-for="productVariant of variantIsActive">
                <v-chip color="primary" :value="productVariant.id" filter
                  @click="handleSelectVariant(productVariant.id)">{{
                    productVariant.name }}</v-chip>
              </template>
            </v-chip-group>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            Tipo:
            <v-chip-group v-model="form.type" mandatory>
              <v-chip value="in" filter color="primary">Entrada</v-chip>
              <v-chip value="out" filter color="error">Saida</v-chip>
            </v-chip-group>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            {{ form.product_name }} - {{ form.product_variant_name }}
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <ProductQuantityField v-model="form.quantity" label="Quantidade" :product="product" />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex justify-space-between">
            <div>Estoque:</div>
            <div>
              <!-- {{ $format.decimal(form.stock) }} -->
              <ProductQuantityLabel :product="product" :quantity="form.stock" />
              <template v-if="form.type == 'in'">
                +
                <ProductQuantityLabel :product="product" :quantity="form.quantity" />
                =
                <ProductQuantityLabel :product="product" :quantity="resultStock" />
              </template>
              <template v-else>
                -
                <ProductQuantityLabel :product="product" :quantity="form.quantity" />
                =
                <ProductQuantityLabel :product="product" :quantity="resultStock" />
              </template>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <AppTextField v-model="form.description" label="Descrição (opcional)" />
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider />
      <v-card-actions class="justify-end pa-6">
        <AppCancelBtn @click="dialog = false" />
        <AppConfirmBtn @click="handleStore()" />
      </v-card-actions>
    </app-card>
  </v-dialog>
</template>

<script setup>
const productStore = useProductStore();

const stockTransactionFormStore = useStockTransactionFormStore();

const { form } = storeToRefs(stockTransactionFormStore);

const $loading = useLoading();

const emit = defineEmits(["store"]);

const product = ref({});

const dialog = ref(false);

const resultStock = computed(() => {
  if (form.value.type == "in") {
    return Number(form.value.stock) + Number(form.value.quantity);
  } else {
    return Number(form.value.stock) - Number(form.value.quantity);
  }
});

const open = async (productId, productVariantId) => {
  stockTransactionFormStore.$reset();

  await loadProduct(productId, productVariantId);

  dialog.value = true;
};

const showVariantSelect = computed(() => {
  return product.value.product_variants.length > 1;
});

const variantIsActive = computed(() => {
  return product.value.product_variants.filter((variant) => variant.status);
});

const handleSelectVariant = (productVariantId) => {

  let variant = product.value.product_variants.find(
    (variant) => variant.id == productVariantId
  );

  form.value.stock = variant.stock;

  form.value.product_variant_id = productVariantId;

  form.value.product_variant_name = variant.name;
};

const loadProduct = async (productId, productVariantId) => {
  $loading.start();

  await productStore
    .getProduct(productId)
    .then((productData) => {
      $loading.stop();

      product.value = productData;

      form.value.product_id = product.value.id;

      form.value.product_name = product.value.name;

      form.value.product_variant_id = productVariantId;

      let variant = product.value.product_variants.find(
        (variant) => variant.id == productVariantId
      );

      form.value.product_variant_name = variant.name;

      form.value.stock = variant.stock;
    })
    .catch((error) => $loading.stop());
};

const handleStore = async () => {
  $loading.start();

  await stockTransactionFormStore
    .store()
    .then((response) => {
      $loading.stop();

      dialog.value = false;

      emit("store", response);
    })
    .catch((error) => $loading.stop());
};



defineExpose({
  open,
});
</script>

<style lang="scss" scoped></style>
