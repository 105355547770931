import { defineStore } from "pinia";

export const useStockTransactionStore = defineStore("stockTransactionStore", {

  state: () => ({

    http: useNuxtApp().$http,

    query: {
      search: null,
      product_id: null,
      page: 1,
      type: null,
    },

    stockTransactions: {
      data: [
      ]
    }
  }),

  actions: {

    async getStockTransactions() {

      const data = await this.http.index("product/stock-transaction", this.query);

      this.stockTransactions = data.stockTransactions
    },
  },
});
