<template>
  <v-row v-if="!singleVariant">
    <v-col>
      <AppTextField v-model="productVariant.name" label="Nome" />
    </v-col>
  </v-row>
  <v-row>
    <v-col cols="12" md="6">
      <AppNumberField v-model="productVariant.price" label="Preço de Venda" />
    </v-col>
    <v-col class="order-md-first order-last" cols="12" md="6">
      <AppNumberField v-model="productVariant.cost" label="Custo" />
    </v-col>
  </v-row>
  <v-row>
    <v-col v-if="showBarcodeScanner" cols="12" md="6">
      <AppBarcodeScanner
        ref="appBarcodeScanner"
        :noLoop="true"
        @input="setGtin"
        :alert="true"
      />
    </v-col>
    <v-col cols="12" md="6">
      <AppTextField
        v-model="productVariant.gtin"
        label="Código de barras"
        @change="validateGtin"
        :append-inner-icon="hasBarcodeDetector ? 'mdi:mdi-barcode-scan' : null"
        @click:append-inner="scanBarcodeScan"
      />
    </v-col>
    <v-col cols="12" md="6">
      <AppTextField v-model="productVariant.reference" label="Referência" />
    </v-col>
  </v-row>
  <v-row v-if="productExists?.id">
    <v-col>
      <v-card density="compact" color="error" variant="tonal">
        <v-card-text>
          <div cols="12">
            <h3>O código de barras já está em uso.</h3>
          </div>

          <div class="d-flex justify-space-between">
            <div>
              <v-checkbox
                density="compact"
                hide-details
                v-model="productVariant.remove_barcode"
                label="Utilizar nesse produto?"
              />
            </div>
            <div class="mt-3">
              <span @click="redirectToProduct()" style="cursor: pointer">
                Acessar produto
              </span>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
  <v-row v-if="showInitialStock">
    <v-col cols="12">
      <ProductQuantityField
        v-model="productVariant.initial_stock"
        label="Informar estoque inicial"
        :product="product"
        :acceptNull="true"
      />
    </v-col>
  </v-row>
  <template v-if="form.control_stock">
    <v-row>
      <v-col cols="12">
        <ProductQuantityField
          v-model="productVariant.stock_min"
          label="Alerta Estoque Mínimo"
          :product="product"
          :acceptNull="true"
        />
      </v-col>
    </v-row>
  </template>
  <v-row v-if="showStock">
    <v-col cols="12">
      Estoque atual: {{ $format.decimal(productVariant.stock) }}
    </v-col>
  </v-row>
</template>

<script setup>
const productForm = useProductFormStore();

const { form, isInsert } = storeToRefs(productForm);

const { $http } = useNuxtApp();

const productExists = ref("");

const prop = defineProps({
  product: {},
  productVariant: {},
  singleVariant: {
    type: Boolean,
    default: false,
  },
});

const showInitialStock = computed(() => {
  return prop.productVariant.id == null && form.value.control_stock;
});

const showBarcodeScanner = ref(false);

const hasBarcodeDetector = computed(() => "BarcodeDetector" in window);

const scanBarcodeScan = () =>
  (showBarcodeScanner.value = !showBarcodeScanner.value);

const setGtin = (gtin) => {
  prop.productVariant.gtin = gtin;
  showBarcodeScanner.value = false;
};

const showStock = computed(() => {
  return (
    form.value.control_stock &&
    !isInsert.value &&
    prop.productVariant.id != null
  );
});

const redirectToProduct = () => {
  let product_id = productExists.value.id;

  let url = `/produtos?product_id=${product_id}`;

  window.open(url, "_blank");
};

const validateGtin = () => {
  if (prop.productVariant && prop.productVariant.gtin) {
    let barcode = prop.productVariant.gtin.replace(/\D/g, "");

    if (barcode) {
      $http
        .index("product/productByBarcode", {
          barcode: barcode,
          statuses: [1, 0],
        })
        .then((response) => {
          let product = response.product;

          if (
            product &&
            product.id == prop.product.id &&
            product.product_variants[0].id == prop.productVariant.id
          ) {
            productExists.value = "";
            return;
          }

          if (product) {
            productExists.value = response.product;

            prop.productVariant.resolve_product = {
              product_id: response.product.id,
              product_variant_id: response.product.product_variants[0].id,
            };
          }
        })
        .catch((error) => console.log(error));
    }
  }
};
</script>

<style lang="scss" scoped></style>
