import { defineStore } from "pinia";

export const useProductFormStore = defineStore("productFormStore", {

  state: () => ({

    http: useNuxtApp().$http,

    analytics: useAnalyticsStore(),

    showInactive: false,

    form: {
      id: null,
      tax_classification_id: null,
      category_id: null,
      supplier_id: null,
      supplier: null,
      category: null,
      unity: null,
      code: null,
      reference: null,
      name: null,
      short_name: null,
      color: null,
      brand: null,
      description: null,
      allow_fractional_sales: false,
      control_stock: true,
      display_on_site : false,
      is_featured : false,
      //notes: null,
      status: true,

      media: [],

      product_variants: [{
        code: null,
        gtin: null,
        name: null,
        commission: 0,
        price: 0,
        cost: 0,
        stock_min: null,
        stock_max: 0,
        initial_stock: null,
        status: true,
      }],
    },
  }),

  getters: {
    isInsert() {
      return this.form.id === null;
    },

    variants() {
      return this.form.product_variants.filter((variant) => {

        if (this.showInactive) {
          return true;
        }

        return variant.status;
      });
    }
  },

  actions: {

    async load(id) {

      const data = await this.http.show("product/product", id);

      this.form = data.product

    },

    async store() {
      if (this.isInsert) {
        
        return await this.insert().then((response) => {
        
          this.analytics.track('Product Created');
        
          return response;
        })
      
      } else {
          
          return await this.update().then((response) => {
          
            this.analytics.track('Product Updated');
          
            return response;
          })
      }
    },

    async insert() {

      return await this.http.store("product/product", this.form);

    },

    async update() {

      return await this.http.update("product/product", this.form.id, this.form);

    },

    async deleteImage(image) {

      if (image.id) {
        await this.http.destroy("product/product-image", image.id, { product_id: this.form.id });
      }

      const index = this.form.media.indexOf(image);

      this.form.media.splice(index, 1);
    }

  },
});
