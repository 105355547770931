<template>
  <v-row>
    <v-col class="py-0">
      <AppSwitch
        v-model="form.control_stock"
        label="Controlar estoque"
        color="primary"
      />
    </v-col>
    <v-col class="text-right">
      <appBtn color="primary" @click="handleStoreStockTransaction()"
        >Ajustar estoque</appBtn
      >
    </v-col>
  </v-row>
  <v-row v-if="hasMultipleVariants">
    <v-col>
      Selecionar variação:
      <v-chip-group>
        <template v-for="productVariant of variantIsActive">
          <v-chip filter @click="handleSelectVariant(productVariant.id)">{{
            productVariant.name
          }}</v-chip>
        </template>
      </v-chip-group>
    </v-col>
  </v-row>
  <template v-if="!hasSelectedVariant">
    <v-row>
      <v-col> Selecione uma variação para visualizar o estoque. </v-col>
    </v-row>
  </template>
  <template v-if="hasSelectedVariant">
    <v-row>
      <v-col>
        <v-card>
          <v-card-text class="d-flex justify-space-between">
            <div>Estoque Atual:</div>
            <div class="text-right">
              <span style="font-size: 24px">
                <ProductQuantityLabel
                  :quantity="currentStock"
                  :product="form"
                />
              </span>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="pt-0">
        <ProductStockTransactionList :stockTransactions="stockTransactions" />
      </v-col>
    </v-row>
  </template>
  <StockTransactionForm
    ref="stockTransactionForm"
    @store="getStockTransactions()"
  />
</template>

<script setup>
const productForm = useProductFormStore();

const { form } = storeToRefs(productForm);

const stockTransactionStore = useStockTransactionStore();

const prop = defineProps({
  productId: {
    type: Number,
    required: true,
  },
});

const { query, stockTransactions } = storeToRefs(stockTransactionStore);

const stockTransactionForm = ref(null);

const variantIsActive = computed(() => {
  return form.value.product_variants.filter((variant) => variant.status);
});

const hasMultipleVariants = computed(() => {
  return form.value.product_variants.length > 1;
});

const hasSelectedVariant = computed(() => {
  return query.value.product_variant_id != null;
});

const currentStock = computed(() => {
  let variant = form.value.product_variants.find((productVariant) => {
    return productVariant.id == query.value.product_variant_id;
  });

  if (variant) {
    return variant.stock;
  }
});

onMounted(() => {
  stockTransactionStore.$reset();

  query.value.product_id = prop.productId;

  if (!hasMultipleVariants.value) {
    query.value.product_variant_id = form.value.product_variants[0].id;

    getStockTransactions();
  }
});

const getStockTransactions = async () => {
  await stockTransactionStore.getStockTransactions(prop.productId);

  loadProduct(prop.productId);
};

const handleStoreStockTransaction = () => {
  stockTransactionForm.value.open(
    prop.productId,
    query.value.product_variant_id
  );
};

const loadProduct = async (id) => {
  await productForm.load(id);
};

const handleSelectVariant = (productVariantId) => {
  query.value.product_variant_id = productVariantId;

  getStockTransactions();
};
</script>

<style lang="scss" scoped></style>