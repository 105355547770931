<template>
  <div id="scanner">
    <div class="line" />
    <video id="stream" />
  </div>
</template>

<script setup>
const emit = defineEmits(["input"]);

const props = defineProps({
  alert: {
    default: false,
  },
  noLoop: {},
});

onMounted(async () => {
  await init();

  if (!props.noLoop) {
  }
});

const hasBarcodeDetector = computed(() => "BarcodeDetector" in window);

const init = async () => {
  if (hasBarcodeDetector.value) {
    const barcodeDetector = new BarcodeDetector({
      formats: [
        "aztec",
        "code_128",
        "code_39",
        "code_93",
        "codabar",
        "data_matrix",
        "ean_13",
        "ean_8",
        "itf",
        "pdf417",
        "qr_code",
        "upc_a",
        "upc_e",
        "unknown",
      ],
    });

    const stream = await navigator.mediaDevices.getUserMedia({
      video: {
        facingMode: {
          ideal: "environment",
        },
        objectFit: "fill",
        focusMode: "continuous",
      },
      audio: false,
    });

    const videoEl = document.querySelector("#stream");

    videoEl.srcObject = stream;

    await videoEl.play();

    setInterval(async () => {
      if (videoEl.srcObject) {
        const barcodes = await barcodeDetector.detect(videoEl);
        if (barcodes.length <= 0) return;
        emitInput(barcodes.map((barcode) => barcode.rawValue)[0]);
      }
    }, 2000);
  }
};

const emitInput = (value) => {
  try {
    emit("input", value);
    showAlert();
  } catch (error) {
  } finally {
    if (props.noLoop) {
      destroy();
    }
  }
};

const destroy = () => {
  const videoEl = document.querySelector("#stream");
  videoEl.srcObject.getTracks().forEach((track) => track.stop());

  videoEl.srcObject = null;
  videoEl.remove();

  const lineEl = document.querySelector(".line");
  lineEl.remove();

  const scannerEl = document.querySelector("#scanner");
  scannerEl.remove();
};

const showAlert = () => {
  if (props.alert) {
    let context = new AudioContext(),
      oscillator = context.createOscillator();
    oscillator.type = "triangle";
    oscillator.connect(context.destination);
    oscillator.start();
    oscillator.stop(context.currentTime + 0.1);

    if (navigator.vibrate) {
      navigator.vibrate(200);
    }
  }
};

defineExpose({ open });
</script>

<style lang="scss" >
#scanner {
  width: 100%;
  height: 80px;
  border: 1px solid #000;
  position: relative;
  .line {
    width: 100%;
    height: 2px;
    top: 50%;
    background-color: #f00;
    z-index: 1;
    position: absolute;
  }
  #stream {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>

