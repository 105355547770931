<template>
  <v-tooltip v-bind="$attrs" :text="text">
    <template v-slot:activator="{ props }">
      <v-icon class="mr-1" :class="[colorClass]" size="x-small" v-bind="($attrs, props)" icon="mdi:mdi-alert-circle" />
    </template>
  </v-tooltip>
</template>

<script setup>
const prop = defineProps({
  product: {
    type: Object,
    required: true,
  },
});

const hasZeroStock = computed(() => {
  return prop.product.product_variants.some(
    (variant) => Number(variant.stock) <= 0
  );
});

const hasLowStock = computed(() => {
  return prop.product.product_variants.some(
    (variant) => Number(variant.stock) <= Number(variant.stock_min)
  );
});

const text = computed(() => {
  return hasZeroStock.value ? "Estoque zerado" : "Estoque baixo";
});

const colorClass = computed(() => {
  // check if has zero stock

  if (hasZeroStock.value) {
    return "text-error";
  }

  if (hasLowStock.value) {
    return "text-warning";
  }

  return "d-none";
});
</script>

<style lang="scss" scoped></style>