import { defineStore } from "pinia";

export const useUnityStore = defineStore("unityStore", {

  state: () => ({

    http: useNuxtApp().$http,

    query: {
      status: 1,
    },

    unities : []
  }),

  actions: {

    async getUnities() {

      const data = await this.http.index("product/unity", this.query);

      this.unities = data.unities
    },
  },
});
