<template>
  <v-list>
    <template v-for="stockTransaction in stockTransactions.data">
      <v-list-item class="px-2" style="cursor: pointer">
        <template v-slot:prepend>
          <!-- <v-icon color="primary" icon="mdi:mdi-cart" /> -->
        </template>

        <template v-if="stockTransaction.origin == 'sale'">
          <template v-if="stockTransaction.status == 'canceled'">
           <span class="text-disabled text-decoration-line-through">Venda #{{ stockTransaction.sale.number }} Cancelada</span>
          </template>
          <template v-else-if="stockTransaction.quantity <= 0">
            Venda #{{ stockTransaction.sale.number }}
          </template>
          <template v-else>
            Devolução #{{ stockTransaction.sale.number }}
          </template>
        </template>

        <template v-if="stockTransaction.origin == 'custom'">
          <template v-if="stockTransaction.quantity > 0">
            Entrada de estoque
            <br>
            <small class="text-medium-emphasis">{{ stockTransaction.description }}</small>
          </template>
          <template v-else>
            Saída de estoque
            <br>
            <small class="text-medium-emphasis">{{ stockTransaction.description }}</small>
          </template>
        </template>

        <template v-slot:append>
          <div :class="[getQuantityTextClass(stockTransaction.quantity)]">
            <ProductQuantityLabel :quantity="stockTransaction.quantity" :product="stockTransaction.product" />
          </div>
        </template>
      </v-list-item>
    </template>
  </v-list>
</template>

<script setup>

const props = defineProps({
  stockTransactions: {
    required: true,
  },
});

const getQuantityTextClass = (quantity) => {
  if (quantity > 0) {
    return "text-success";
  }

  if (quantity < 0) {
    return "text-error";
  }

  return "text-medium-emphasis";
};

const getText = (stockTransaction) => {
  if (stockTransaction.origin === "sale") {
    return `Venda #${stockTransaction.sale_id}`;
  }

  if (stockTransaction.origin === "purchase") {
    return `Compra #${stockTransaction.purchase_id}`;
  }

  if (stockTransaction.origin === "custom") {
    return 'Lançamento manual'
  }


};

</script>

<style lang="scss" scoped></style>